import "@css/app.css";

/* JS */
import "lazysizes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
 import Alpine from "alpinejs";
 window.Alpine = Alpine;
 Alpine.start();
 

 /*
 import PhotoSwipeLightbox from "photoswipe/lightbox";
 import "photoswipe/style.css";
 
 Array.from(document.querySelectorAll(".gallery")).map((g) => {
   const lightbox = new PhotoSwipeLightbox({
     gallery: g,
     children: "a",
     initialZoomLevel: 'fit',
     pswpModule: () => import("photoswipe"),
   });
   lightbox.init();
 });

 /*
 Array.from(document.querySelectorAll(".accessories a")).map((h) => {
  const options = {
    gallery: h,
    initialZoomLevel: 'fit',
    arrowPrev: false,
    arrowNext: false,
    zoom: false,
    counter: false,
    pswpModule: () => import("photoswipe"),
  };
  const lightbox = new PhotoSwipeLightbox(options);
  lightbox.init();
});
*/
/*
const options = {
  gallery: '#gallery--individual a',
  pswpModule: () => import('/photoswipe/photoswipe.esm.js')
};
const lightbox = new PhotoSwipeLightbox(options);
lightbox.init();
*/

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";